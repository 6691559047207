define("ember-light-table/templates/components/lt-foot", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "CDAmw6KT",
    "block": "[[[6,[39,0],null,[[\"to\",\"renderInPlace\"],[[28,[37,1],[[33,2],\"_inline_foot\"],null],[33,3]]],[[\"default\"],[[[[1,\"  \"],[10,\"table\"],[15,0,[36,4]],[12],[1,\"\\n    \"],[10,\"tfoot\"],[14,0,\"lt-foot\"],[12],[1,\"\\n\"],[1,\"      \"],[1,[28,[35,5],null,[[\"columns\"],[[33,6]]]]],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"        \"],[18,2,[[33,6]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,11],[[28,[37,11],[[33,6]],null]],null],null,[[[1,\"            \"],[46,[28,[37,1],[\"light-table/columns/\",[30,1,[\"type\"]]],null],[[30,1]],[[\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[33,13],[33,14],[33,15],[33,16],[33,17],[28,[37,18],[[30,0],\"onColumnClick\",[30,1]],null],[28,[37,18],[[30,0],\"onColumnDoubleClick\",[30,1]],null],[28,[37,18],[[30,0],\"onColumnResized\"],null],[28,[37,18],[[30,0],\"onColumnDrag\"],null],[28,[37,18],[[30,0],\"onColumnDrop\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"column\",\"&default\"],false,[\"ember-wormhole\",\"concat\",\"tableId\",\"renderInPlace\",\"tableClassNames\",\"lt-scaffolding-row\",\"columns\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"component\",\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"action\"]]",
    "moduleName": "ember-light-table/templates/components/lt-foot.hbs",
    "isStrictMode": false
  });
});