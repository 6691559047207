define("ember-light-table/templates/components/lt-scaffolding-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NyFR2KK0",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"td\"],[15,5,[28,[37,3],[[52,[30,1,[\"width\"]],[28,[37,5],[\"width: \",[30,1,[\"width\"]]],null]]],null]],[14,0,\"lt-scaffolding\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"column\"],false,[\"each\",\"-track-array\",\"columns\",\"html-safe\",\"if\",\"concat\"]]",
    "moduleName": "ember-light-table/templates/components/lt-scaffolding-row.hbs",
    "isStrictMode": false
  });
});