define("ember-light-table/utils/css-styleify", ["exports", "@ember/string", "@ember/utils"], function (_exports, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cssStyleify;
  function cssStyleify(hash = {}) {
    let styles = [];
    Object.keys(hash).forEach(key => {
      let value = hash[key];
      if ((0, _utils.isPresent)(value)) {
        styles.push(`${(0, _string.dasherize)(key)}: ${value}`);
      }
    });
    return (0, _string.htmlSafe)(styles.join('; '));
  }
});