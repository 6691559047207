define("ember-light-table/components/lt-row", ["exports", "@ember/component", "@ember/object", "ember-light-table/templates/components/lt-row"], function (_exports, _component, _object, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Row = _component.default.extend({
    layout: _ltRow.default,
    tagName: 'tr',
    classNames: ['lt-row'],
    classNameBindings: ['isSelected', 'isExpanded', 'canExpand:is-expandable', 'canSelect:is-selectable', 'row.classNames'],
    attributeBindings: ['colspan', 'data-row-id'],
    columns: null,
    row: null,
    tableActions: null,
    extra: null,
    canExpand: false,
    canSelect: false,
    colspan: 1,
    isSelected: _object.computed.readOnly('row.selected'),
    isExpanded: _object.computed.readOnly('row.expanded')
  });
  Row.reopenClass({
    positionalParams: ['row', 'columns']
  });
  var _default = _exports.default = Row;
});